import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Category } from '../models/category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  public categories: Observable<Category[]> = this.http
    .get<Category[]>(`${environment.apiBaseUrl}/categories`)
    .pipe(tap((res) => console.log('response', res)));

  constructor(private http: HttpClient) {}

  getCategoriesById(id: string): Observable<HttpResponse<Category>> {
    return this.http.get<Category>(`${environment.apiBaseUrl}/categories/${id}`, { observe: 'response' });
  }

  updateCategory(id: string, category: Category) {
    if (id === '0') {
      return this.http.post(`${environment.apiBaseUrl}/categories`, category);
    } else {
      return this.http.put(`${environment.apiBaseUrl}/categories/${id}`, category);
    }
  }

  deleteCategory(id: string) {
    return this.http.delete(`${environment.apiBaseUrl}/categories/${id}`);
  }
}
