import { ClientFormModel } from './../models/client-form-model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Client } from '../models/client';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ClientService {
  URL = environment.apiBaseUrl;

  constructor(private http: HttpClient) {}

  getClients(): Observable<HttpResponse<Client[]>> {
    return this.http.get<Client[]>(`${this.URL}/clients`, { observe: 'response' });
  }

  getClientById(id: string): Observable<HttpResponse<ClientFormModel>> {
    return this.http.get<ClientFormModel>(`${this.URL}/clients/${id}`, { observe: 'response' });
  }

  updateClient(id: string, client: Client) {
    if (id.toString() === '0') {
      return this.http.post(`${this.URL}/clients`, client);
    } else {
      return this.http.put(`${this.URL}/clients/${id}`, client);
    }
  }

  deleteClient(id: string) {
    return this.http.delete(`${this.URL}/client/${id}`);
  }
}
