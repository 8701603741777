import { DashboardService } from './state/dashboard.service';
import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.scss' ]
})
export class DashboardComponent implements OnInit {
  bigChart = [];
  cards = [];
  pies = [];
  tables = [];
  constructor(private dashboardService: DashboardService) {}

  ngOnInit() {
    this.bigChart = this.dashboardService.bigCharts();
    this.cards = this.dashboardService.cards();
    this.pies = this.dashboardService.pies();
    this.tables = this.dashboardService.tables();
  }
}
