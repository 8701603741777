import { JobCardFormModel } from './../models/job-card-form-model';
import { JobCard } from './../models/job-card';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobCardService {

  URL = environment.apiBaseUrl;

  constructor(private http: HttpClient) { }

  getJobcardByStatus(statusName: string): Observable<HttpResponse<JobCard[]>> {
    return this.http.get<JobCard[]>(`${this.URL}/jobcardByStatus/${statusName}`, { observe: 'response' });
  }

  getJobCardById(id: string): Observable<HttpResponse<JobCardFormModel>> {
    return this.http.get<JobCardFormModel>(`${this.URL}/jobCards/${id}`, { observe: 'response' });
  }

  updateJobCard(id: string, jobCard: JobCard): Observable<HttpResponse<string>> {
    if (id === '0') {
      return this.http.post<string>(`${this.URL}/jobCards`, jobCard, { observe: 'response'});
    } else {
      return this.http.put<string>(`${this.URL}/jobCards/${id}`, jobCard, { observe: 'response'});
    }
  }

  deleteJobCard(id: string): Observable<HttpResponse<string>> {
    return this.http.delete<string>(`${this.URL}/jobCards/${id}`, { observe: 'response'});
  }

  toggleJobCardStatus(id: string): Observable<HttpResponse<string>> {
    return this.http.get<string>(`${this.URL}/status/${id}`, { observe: 'response'});
  }
}
