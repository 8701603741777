import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [ './sidebar.component.scss' ]
})
export class SidebarComponent implements OnInit {
  public links = LINKS;
  constructor() {}

  ngOnInit() {}
}
export interface ILinkItem {
  url: string;
  icon: string;
  label: string;
}

const LINKS: ILinkItem[] = [
  {
    url: '/',
    icon: 'dashboard',
    label: 'Dashboard'
  },
  {
    url: '/jobcard',
    icon: 'work_outline',
    label: 'Jobs Cards'
  },
  {
    url: '/client',
    icon: 'perm_identity',
    label: 'Clients'
  },
  {
    url: '/technician',
    icon: 'engineering',
    label: 'Technicians'
  },
  {
    url: '/category',
    icon: 'category',
    label: 'Categories'
  }
];
