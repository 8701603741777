import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-widgets-table',
  templateUrl: './table.component.html',
  styleUrls: [ './table.component.scss' ]
})
export class TableComponent implements OnInit {
  @Input() dataSource = [];
  displayedColumns: string[] = [ 'position', 'name', 'weight', 'symbol' ];
  constructor() {}

  ngOnInit() {}
}
