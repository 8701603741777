import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ]
})
export class HeaderComponent implements OnInit {
  @Output() myToggleSideBar: EventEmitter<Event> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  toggleSideBar(): void {
    this.myToggleSideBar.emit();
  }
}
