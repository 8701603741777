import { JobCardService } from './../../services/job-card.service';
import { JobCard } from './../../models/job-card';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-jobcard-list',
  templateUrl: './jobcard-list.component.html',
  styleUrls: [ './jobcard-list.component.scss' ]
})
export class JobcardListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: false })
  sort: MatSort;

  title = 'Job Cards';
  isLoading = false;
  isConfirmed = false;
  dataSource: MatTableDataSource<JobCard>;
  jobCards = [] as JobCard[];

  displayedColumns = [ 'technician', 'number', 'title', 'description', 'jobStatus', 'startDate', 'active', 'actions' ];
  constructor(private service: JobCardService, public dialog: MatDialog, public snackBar: MatSnackBar) {}

  ngOnInit() {
    this.service.getJobcardByStatus(null).subscribe((res) => {
      this.jobCards = res.body;
      this.dataSource = new MatTableDataSource<JobCard>(this.jobCards);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  activeStatusChanged(id: string) {
    this.service.toggleJobCardStatus(id).subscribe((res) => {
      console.log(res.body);
    });
  }

  // client side filtering
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
