import { JobcardListComponent } from './pages/jobcard-list/jobcard-list.component';
import { TechnicianListComponent } from './pages/technician-list/technician-list.component';
import { ClientListComponent } from './pages/client-list/client-list.component';
import { CategoryFormComponent } from './pages/category/category-form/category-form.component';
import { CategoryListComponent } from 'src/app/pages/category/category-list/category-list.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DefaultComponent } from './layouts/default/default.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './auth/auth.guard';
import { InterceptorService } from './auth/interceptor.service';
const routes: Routes = [
  {
    path: '',
    component: DefaultComponent,
    children: [
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'category',
        component: CategoryListComponent
      },
      {
        path: 'category-form',
        component: CategoryFormComponent
      },
      {
        path: 'client',
        component: ClientListComponent
      },
      {
        path: 'jobcard',
        component: JobcardListComponent
      },
      {
        path: 'technician',
        component: TechnicianListComponent
      }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: InterceptorService,
    //   multi: true
    // }
  ]
})
export class AppRoutingModule {}
