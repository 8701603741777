import { CategoryService } from '../../../services/category.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Category } from 'src/app/models/category';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: [ './category-list.component.scss' ]
})
export class CategoryListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: false })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: false })
  sort: MatSort;

  title = 'Categories';
  isLoading = false;
  isConfirmed = false;
  dataSource: MatTableDataSource<Category>;

  displayedColumns = [ 'name', 'description', 'active', 'created', 'actions' ];
  constructor(private service: CategoryService, public dialog: MatDialog, public snackBar: MatSnackBar) {}

  ngOnInit() {
    this.service.categories.subscribe((response) => {
      this.dataSource = new MatTableDataSource<Category>(response);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  ngAfterViewInit() {}

  activeStatusChanged(id: string) {
    console.log(id);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  deleteCategory(id: string): void {
    console.log(id);
  }
}
