import { Technician } from './../models/technician';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TechnicianFormModel } from '../models/technician-form-model';

@Injectable({
  providedIn: 'root'
})
export class TechnicianService {
  URL = environment.apiBaseUrl;
  constructor(private http: HttpClient) { }


  technicians$ = this.http.get<Technician[]>(`${this.URL}/technicians`);


  getTechnicianById(id: string): Observable<HttpResponse<TechnicianFormModel>> {
    return this.http.get<TechnicianFormModel>(`${this.URL}/technicians/${id}`, { observe: 'response' });
  }

  updateTechnician(id: string, technician: Technician) {
    if (id.toString() === '0') {
      return this.http.post(`${this.URL}/technicians`, technician);
    } else {
      return this.http.put(`${this.URL}/technicians/${id}`, technician);
    }
  }

  deleteTechnician(id: string) {
    return this.http.delete(`${this.URL}/technicians/${id}`);
  }
}
