import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
@Component({
  selector: 'app-widgets-card',
  templateUrl: './card.component.html',
  styleUrls: [ './card.component.scss' ]
})
export class CardComponent implements OnInit {
  @Input() data = [];
  @Input() label: string;
  @Input() total: string;
  @Input() percentage: string;

  highcharts = Highcharts;
  chartOptions = {};

  constructor() {}

  ngOnInit() {
    this.chartOptions = {
      chart: {
        type: 'area',
        backgroundColor: null,
        borderwidth: 0,
        margin: [ 2, 2, 2, 2 ],
        heigth: 60
      },
      title: {
        text: null
      },
      subtitle: {
        text: null
      },

      tooltip: {
        split: true,
        outside: true
      },
      legend: { enabled: false },
      credits: { enabled: false },
      exporting: { enabled: false },
      xAxis: {
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        startOnTick: false,
        endOnTick: false,
        tickOptions: []
      },
      yAxis: {
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        startOnTick: false,
        endOnTick: false,
        tickOptions: []
      },
      series: [
        {
          data: this.data
        }
      ]
    };
    HC_exporting(Highcharts);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }
}
