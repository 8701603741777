import { Technician } from './../../models/technician';
import { MatSort } from '@angular/material/sort';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TechnicianService } from 'src/app/services/technician.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-technician-list',
  templateUrl: './technician-list.component.html',
  styleUrls: [ './technician-list.component.scss' ]
})
export class TechnicianListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: false })
  sort: MatSort;

  title = 'Categories';
  isLoading = false;
  isConfirmed = false;
  dataSource = this.service.technicians$;
  technicians = [] as Technician[];

  displayedColumns = [ 'name', 'description', 'active', 'created', 'phoneNumber', 'alternativeNumber', 'actions' ];

  constructor(private service: TechnicianService, public dialog: MatDialog, public snackBar: MatSnackBar) {}

  ngOnInit() {
    // this.service.getTechicians().subscribe((res) => {
    //   this.technicians = res.body;
    //   this.dataSource = new MatTableDataSource<Technician>(this.technicians);
    //   this.dataSource.sort = this.sort;
    //   this.dataSource.paginator = this.paginator;
    // });
  }

  deleteTechnician(id: string) {
    console.log('Technician deleted: ', id);
  }

  activeStatusChanged(id: string) {
    console.log('Changes the active status of the technician');
  }

  // client side filtering
  applyFilter(filterValue: string) {
    console.log(filterValue);
    // this.dataSource.filter = filterValue.trim().toLowerCase();

    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }
}
