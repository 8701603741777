import { DashboardService } from './../../pages/dashboard/state/dashboard.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TechnicianListComponent } from './../../pages/technician-list/technician-list.component';
import { JobcardListComponent } from './../../pages/jobcard-list/jobcard-list.component';
import { ClientListComponent } from './../../pages/client-list/client-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DefaultComponent } from './default.component';
import { MaterialModule } from './../../modules/material.module';
import { DashboardComponent } from './../../pages/dashboard/dashboard.component';
import { CategoryListComponent } from './../../pages/category/category-list/category-list.component';
import { CategoryFormComponent } from './../../pages/category/category-form/category-form.component';
import { SharedModule } from './../../shared/shared.module';
@NgModule({
  declarations: [
    DefaultComponent,
    DashboardComponent,
    CategoryListComponent,
    CategoryFormComponent,
    ClientListComponent,
    JobcardListComponent,
    TechnicianListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FlexLayoutModule
  ],
  providers: [ DashboardService ]
})
export class DefaultModule {}
