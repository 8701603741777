import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from 'src/app/models/category';
import { HttpErrorResponse } from '@angular/common/http';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: [ './category-form.component.scss' ]
})
export class CategoryFormComponent implements OnInit {
  title = 'Create Category';
  private id: string;
  category = {} as Category;

  categoryForm = this.fb.group({
    name: [ null, Validators.required ],
    description: [ null, Validators.required ],
    active: [ false, Validators.required ],
    created: [ null, Validators.required ]
  });

  constructor(
    private fb: FormBuilder,
    private service: CategoryService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');

    if (this.id !== '0') {
      this.title = 'Update Category';
      this.getCategory(this.id);
    }
  }

  onSubmit() {
    if (this.categoryForm.invalid) {
      return;
    }

    this.service.updateCategory(this.id, this.categoryForm.value).subscribe(
      (c) => {
        this.router.navigate([ '/categories' ]);
      },
      (err: HttpErrorResponse) => console.log(err)
    );
  }

  getCategory(id: string): void {
    this.service.getCategoriesById(id).subscribe(
      (c) => {
        this.editCategory(c.body);
      },
      (err: HttpErrorResponse) => console.log(err)
    );
  }
  editCategory(category: Category) {
    this.categoryForm.patchValue({
      id: this.id,
      name: category.name,
      description: category.description,
      created: category.created,
      active: category.active
    });
  }
}
