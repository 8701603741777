import { ClientService } from './../../services/client.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Client } from 'src/app/models/client';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: false })
  sort: MatSort;

  title = 'Categories';
  isLoading = false;
  isConfirmed = false;
  dataSource: MatTableDataSource<Client>;
  clients = [] as Client[];

  displayedColumns = ['name', 'description', 'active', 'phoneNumber', 'created', 'actions'];

  constructor(private service: ClientService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.service.getClients().subscribe((res) => {
      this.clients = res.body;
      this.dataSource = new MatTableDataSource<Client>(this.clients);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  deleteClient(id: string) {
    console.log('Client deleted: ', id);
  }

  activeStatusChanged(id: string) {
    console.log('Changes the active status of the client');
  }

    // client side filtering
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
